











































import { promiseHelper } from '@/helper/promise-helper'
import { ProfileModel } from '@/models/profile-model'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop({}) profile!: ProfileModel
  dialog = false
  resolve?: (value: any) => void

  confirm(val: boolean) {
    this.resolve && this.resolve(val)
    this.dialog = false
  }

  async open() {
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((resolve) => (this.resolve = resolve))
  }

  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
    await promiseHelper.delay(100)
  }
}
